<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 occupancy-screen reports-screen technical-list-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="occupancy-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_occupancy-help-online-technical-list'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("occupancy.header.technical-list") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="getData(false, true)"
        :hasFilters="true"
        :filters="filters"
        routeName="r_occupancy-technical-list-search-filters"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreenItem }}/{{ paginationData.total }}</span
        >
      </div>
    </template>
    <div v-if="filtersChanged" class="filters-changed-overlay"></div>
    <ul v-if="filtersChanged" class="clebex-item-section search-refresh">
      <li class="clebex-item-section-item no-bottom-border">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("occupancy", "dashboard", "refresh")
            }}</label>
          </div>
          <span @click="getData(true, true)" class="follow-up-icon-item">
            <icon icon="#cx-foo1-refresh-28x28" />
          </span>
        </div>
      </li>
    </ul>
    <ul
      v-if="occupancyData && occupancyData.length"
      class="clebex-item-section technical-list technical-list-header"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column">
            {{ displayLabelName("occupancy", "technical-list", "location") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("occupancy", "technical-list", "type") }}
          </div>
          <div class="clebex-item-column">
            {{
              displayLabelName(
                "occupancy",
                "technical-list",
                "sensor-information"
              )
            }}
          </div>
          <div class="clebex-item-column">
            {{
              displayLabelName("occupancy", "technical-list", "serial-number")
            }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("occupancy", "technical-list", "value") }}
          </div>
          <div class="clebex-item-column">
            {{
              displayLabelName("occupancy", "technical-list", "last-pull-out")
            }}
          </div>
          <div class="clebex-item-column">
            {{
              displayLabelName(
                "occupancy",
                "technical-list",
                "last-communication"
              )
            }}
          </div>
        </div>
      </li>
    </ul>
    <ul
      ref="technicalList"
      @scroll="handleScroll"
      v-if="occupancyData && occupancyData.length"
      class="clebex-item-section technical-list"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-for="item in occupancyData"
        :key="item.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName("occupancy", "technical-list", "location")
              }}:</span
            >
            {{ item.location }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName("occupancy", "technical-list", "type")
              }}:</span
            >
            {{ item.type_name }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName(
                  "occupancy",
                  "technical-list",
                  "sensor-information"
                )
              }}:</span
            >
            {{ item.resource }}
          </div>
          <div class="clebex-item-column">{{ item.serial }}</div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName("occupancy", "technical-list", "value")
              }}:</span
            >
            {{ item.value }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName(
                  "occupancy",
                  "technical-list",
                  "last-pull-out"
                )
              }}:</span
            >
            {{ item.last_pullout }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName(
                  "occupancy",
                  "technical-list",
                  "last-pull-out"
                )
              }}:</span
            >
            {{ item.last_comunication }}
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <occupancy-footer-menu></occupancy-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import OccupancyFooterMenu from "@/components/occupancy/OccupancyFooterMenu";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OccupancyTechnicalList",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search")),
    OccupancyFooterMenu
  },
  data() {
    return {
      search: null,
      topScreenItem: 1,
      itemHeight: null,
      itemListTop: null,
      paginationCountActive: false,
      timeout: null,
      helpSlug: "occupancy-technical-list"
    };
  },
  created() {
    this.setInitialValues();
    if (this.$route.matched.length > 1) {
      this.search = true;
    }
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
    }
  },
  computed: {
    ...mapState("searchFilters", [
      "filters",
      "filtersChanged",
      "filtersClosed"
    ]),
    ...mapState("occupancy", [
      "occupancyData",
      "paginationData",
      "paginationLoading"
    ])
  },
  methods: {
    ...mapActions("occupancy", ["getOccupancyFilters", "calculate"]),
    ...mapActions("searchFilters", ["resetFilters"]),
    toggleSearch() {
      this.search = !this.search;
    },
    modifySearchQuery(searchQuery) {
      this.$store.commit("search/setSearchQuery", searchQuery, {
        root: true
      });
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.getData(false, true);
    },
    getData(filtersChanged, reset) {
      this.calculate({
        section: "technical_list",
        pagination: true,
        reset: reset
      });
      if (filtersChanged) {
        this.$store.commit("searchFilters/setFiltersChanged", false, {
          root: true
        });
      }
    },
    setInitialValues() {
      this.$store.commit("occupancy/setPaginationData", null, {
        root: true
      });
      this.modifySearchQuery("");
      this.calculate({
        section: "technical_list",
        initial: true,
        pagination: true,
        reset: false
      });
      this.getOccupancyFilters("technical_list");
    },
    handleScroll() {
      if (this.$refs.technicalList) {
        this.topScreenItem =
          Math.floor(
            (this.itemListTop -
              this.$refs.technicalList.childNodes[1].getBoundingClientRect()
                .top) /
              this.itemHeight
          ) + 1;
      }
    }
  },
  watch: {
    occupancyData() {
      this.$nextTick(() => {
        if (!this.itemListTop && this.$refs.technicalList) {
          this.itemListTop = this.$refs.technicalList.getBoundingClientRect().top;
          this.itemHeight = this.$refs.technicalList.childNodes[1].offsetHeight;
        }
      });
    },
    topScreenItem() {
      if (
        this.paginationData.current_page > 2 &&
        !this.paginationLoading &&
        this.paginationData.to - this.topScreenItem < 200 &&
        this.paginationData.current_page < this.paginationData.last_page
      ) {
        this.calculate({
          section: "technical_list",
          pagination: true,
          reset: false
        });
      }
      if (this.topScreenItem > 1) {
        this.paginationCountActive = true;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.paginationCountActive = false;
        }, 3000);
      }
    },
    filtersClosed: {
      handler(val) {
        if (val) {
          if (this.filtersChanged) {
            this.calculate({
              section: "technical_list",
              pagination: true,
              reset: true
            });
            this.$store.commit("searchFilters/setFiltersChanged", false, {
              root: true
            });
          }
          this.$store.commit("searchFilters/setFiltersClosed", false, {
            root: true
          });
        }
      },
      immediate: true
    }
  },
  beforeUnmount() {
    this.resetFilters();
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", false, {
        root: true
      });
    }
  }
};
</script>
